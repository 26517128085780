import React from 'react';

import Seo from '../components/Seo';

import sections from '../constants/sections';

function SpitzGermany() {
  return <Seo title={sections.SpitzGermany.title} />;
}

export default SpitzGermany;
